/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "@ng-select/ng-select/themes/default.theme.css";
@import "primeicons/primeicons.css";

$primary-color: #01579B;
$secondary-color: #F4F4FF;
$primary-light-color: #bbdefb;
$light-color: #F4F4FF;


/* -------------------------------------------------------------------------- */
/*                             ANCHOR FONT WEIGHT                             */
/* -------------------------------------------------------------------------- */

@for $i from 1 through 9 {
  .fw-#{$i * 100} {
    font-weight: $i * 100;
  }
}

/* -------------------------------------------------------------------------- */
/*                              ANCHOR BACKGROUND                             */
/* -------------------------------------------------------------------------- */

.bg-center {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-primary {
  background-color: $primary-color !important;
}

.bg-primary-light {
  background-color: $primary-light-color !important;
}

.bg-secondary {
  background-color: $secondary-color !important;
}

.bg-white-semi-transparent {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

/* -------------------------------------------------------------------------- */
/*                                 ANCHOR BTN                                 */
/* -------------------------------------------------------------------------- */

.btn-primary {
  background-color: $primary-color;
  border-color: $primary-color;
  color: white;
}

/*
████████╗███████╗██╗  ██╗████████╗
╚══██╔══╝██╔════╝╚██╗██╔╝╚══██╔══╝
   ██║   █████╗   ╚███╔╝    ██║
   ██║   ██╔══╝   ██╔██╗    ██║
   ██║   ███████╗██╔╝ ██╗   ██║
   ╚═╝   ╚══════╝╚═╝  ╚═╝   ╚═╝

*/

.text-primary {
  color: $primary-color !important;
}

.shimmer {
  animation: animate 2.5s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

@keyframes animate {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(111, 112, 111, 0.2);
  border-right: 1.1em solid rgba(111, 112, 111, 0.2);
  border-bottom: 1.1em solid rgba(111, 112, 111, 0.2);
  border-left: 1.1em solid #464646;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}